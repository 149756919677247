import * as React from "react";
import { StoreContext } from "../store/storeContext";
import { exportExcel } from "../utils/exportExcel";
import {
  fetchPendingInspect,
  requestBadGoods,
  aproveInspection,
} from "../services/services";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { FiEdit } from "react-icons/fi";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import "../components/Table/table.css";
import Grid from "@mui/material/Unstable_Grid2";
import { Switch, Typography, Popover, Button, IconButton } from "@mui/material";
import clsx from "clsx";
import { alpha, styled } from "@mui/material/styles";

import { UTCtoMMDDYYYYHHMM } from "../utils/utils";
const useTableController = () => {
  const {
    typeOfInspection,
    tableLocalData,
    chassisInspections,
    loading,
    fetchData,
    setLoading,
    setShowModal,
    setRowData,
    user,
    setToast,
    filteredData,
  } = React.useContext(StoreContext);
  //LOCAL CONSTANTS   -----------------------------------------------------------------------------------------
  const [openDialog, setOpenDialog] = React.useState({
    state: false,
    row: {},
  });

  const chassisColumns = [
    {
      field: "inspectionId",
      width: 150,
      flex: 1,
      headerName: "Inspection ID",
      headerAlign: "center",
      valueFormatter: (params) => params.value || "N/A",
      align: "center",
    },
    {
      field: "chassisNumber",
      width: 150,
      flex: 1,
      headerName: "Chasis Number",
      headerAlign: "center",
      valueFormatter: (params) => params.value || "N/A",
      align: "center",
    },
    {
      field: "ownerScacCode",
      width: 150,
      flex: 1,
      headerName: "Owner SCAC",
      headerAlign: "center",
      valueFormatter: (params) => params.value || "N/A",
      align: "center",
    },

    {
      field: "carrierScacCode",
      width: 150,
      flex: 1,
      headerName: "Carrier SCAC",
      headerAlign: "center",
      valueFormatter: (params) => params.value || "N/A",
      align: "center",
    },
    {
      field: "inspectionState",
      width: 150,
      flex: 1,
      headerName: "Inspection State",
      headerAlign: "center",
      valueFormatter: (params) => params.value || "N/A",
      align: "center",
    },
    {
      field: "reason",
      width: 150,
      flex: 1,
      headerName: "In/Out",
      headerAlign: "center",
      valueFormatter: (params) => params.value || "N/A",
      align: "center",
    },
    {
      field: "transactionDate",
      width: 150,
      flex: 1,
      headerName: "Transaction Date",
      headerAlign: "center",
      valueFormatter: (params) => params.value || "N/A",
      align: "center",
    },
    {
      field: "inspect",
      headerName: "Inspect",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.inspectionState === "Pending") {
          return (
            <IconButton
              variant="contained"
              onClick={() => {
                setOpenDialog({ state: true, row: params.row });
              }}
            >
              <EditCalendarIcon />
            </IconButton>
          );
        }
      },
    },
  ];

  const columns = [
    {
      field: "tmp",
      width: 150,
      headerName: "TMP",
      headerAlign: "center",
      valueFormatter: (params) => params.value || "N/A",
      align: "center",
    },
    {
      field: "DeliveryDate",
      headerName: "Delivery Date",
      valueFormatter: (params) =>
        params.value ? UTCtoMMDDYYYYHHMM(params.value) : "N/A",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "CreatedAt",
      headerName: "Time Input",
      valueFormatter: (params) =>
        params.value ? UTCtoMMDDYYYYHHMM(params.value) : "N/A",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Location",
      headerName: "Yard Location/Dropping Off",
      valueFormatter: (params) => params.value || "N/A",
      headerAlign: "center",
      width: 150,
      align: "center",
    },
    {
      field: "containerNumber",
      headerName: "Container Number",
      valueFormatter: (params) => params.value || "N/A",
      width: 170,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Carrier",
      headerName: "SNE/OWNER OP",
      valueFormatter: (params) => params.value || "N/A",
      headerAlign: "center",
      width: 120,
      align: "center",
    },
    {
      field: "chasisOutgate",
      headerName: "Chassis",
      valueFormatter: (params) => params.value || "GROUNDED",
      // if Grounded, color red
      cellClassName: (params) => {
        return params.value ? "" : "grounded-cell";
      },
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "DeliveryLocation",
      headerName: "Customer/Delivery Location",
      valueFormatter: (params) => params.value || "N/A",
      width: 170,
      headerAlign: "center",
      align: "center",
      fontSize: "0.75rem",
    },
    {
      field: "FlipStatus",
      headerName: "Flip",
      valueFormatter: (params) => params.value || "N/A",
      width: 170,
      headerAlign: "center",
      align: "center",
      fontSize: "0.75rem",
    },
    {
      field: "IsDropOff",
      headerName: "External/Internal",
      valueFormatter: (params) => (params.value ? "External" : "Internal"),
      width: 170,
      headerAlign: "center",
      align: "center",
      fontSize: "0.75rem",
    },
    {
      field: "FoodGrade",
      headerName: "Food Grade",
      valueFormatter: (params) => (params.value ? "YES" : "NO"),
      cellClassName: (params) => {
        return clsx("super-app", {
          good: params.value,
          bad: !params.value,
        });
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "statusInspection",
      headerName: "Good/Bad",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignSelf: "center" }}>
            <CheckIcon
              onClick={() => handleGoodBadRequest(params.row, true)}
              className={
                params.value === true
                  ? "button-good-true  buttonRequest-pending-inspect"
                  : "buttonRequest-pending-inspect button-good"
              }
            />
            <CloseIcon
              onClick={() => handleGoodBadRequest(params.row, false)}
              className={
                params.value === false
                  ? "button-bad-true button-bad buttonRequest-pending-inspect"
                  : " buttonRequest-pending-inspect button-bad"
              }
            />
          </div>
        );
      },
    },
    {
      field: "ready",
      headerName: "Ready",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <ReadySwitch
              checked={params.value}
              onChange={() => {
                params.row.ready = !params.row.ready;
                handleRequestInspection(params.row);
              }}
            />
          </div>
        );
      },
    },
    {
      field: "Comment",
      sortable: false,
      headerName: "Comments",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handlePopoverOpen = (event) => {
          setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
          setAnchorEl(null);
        };

        const open = Boolean(anchorEl);

        return (
          <>
            <Grid container minWidth={"100%"}>
              <Grid
                xs={9}
                style={{
                  display: "flex",
                  alignSelf: "center",
                  overflow: "hidden",
                }}
              >
                <Typography
                  id={`comments-${params.row.id}`}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  aria-owns={
                    open ? `mouse-over-popover-${params.row.id}` : undefined
                  }
                  aria-haspopup="true"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  {params.value ? params.value : "No comments..."}
                </Typography>
                {params.value ? (
                  <Popover
                    id={`mouse-over-popover-${params.row.id}`}
                    sx={{
                      pointerEvents: "none",
                    }}
                    PaperProps={{
                      elevation: 1,
                      style: { maxWidth: "300px" },
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1 }}>{params.value}</Typography>
                  </Popover>
                ) : null}
              </Grid>
              <Grid
                xs={3}
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <SpeakerNotesIcon
                  style={{
                    color: "#1c7d2a",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                  id="buttonRequest-pending-inspect"
                  onClick={() => handleOpenModal(params)}
                />
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: "Edit",
      headerName: "Edit DropOff",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          params.row.IsDropOff && (
            <div style={{ display: "flex", alignSelf: "center" }}>
              <FiEdit
                id="optionIcon"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
                onClick={() => handleEditDropOff(true, params.row)}
              />
            </div>
          )
        );
      },
    },
  ];

  const ReadySwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#1c7d2a",
      "&:hover": {
        backgroundColor: alpha("#1c7d2a", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1c7d2a",
    },

    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      color: "red",
      backgroundColor: "red",
    },
    "& .MuiSwitch-switchBase": {
      color: "red",
      "&:hover": {
        backgroundColor: alpha("#ff0000", theme.palette.action.hoverOpacity),
      },
    },
  }));
  //USEEFFECT--------------------------------------------------------------------------------------

  React.useEffect(() => {
    // Modificar el estilo del MuiDataGrid-virtualScroller para que el desplazamiento vertical sea visible
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
            .MuiDataGrid-virtualScroller {
                overflow-y: auto !important;
            }
        `;
    document.head.appendChild(styleTag);

    return () => {
      document.head.removeChild(styleTag);
    };
  }, []); //
  //FUNCTIONS OR METHODS-----------------------------------------------------------------------------------------
  const handleEditDropOff = (show, data) => {
    var item = { cont_trl_num: data.containerNumber };
    const showDropOff = new CustomEvent("editDropOff", {
      detail: { show, item },
    });
    window.dispatchEvent(showDropOff);
  };

  const handleExportExcel = () => {
    exportExcel(tableLocalData, "PendingInspect");
  };

  const handleRequestInspection = (row, value) => {
    setLoading(true);
    const object = {
      id: row.id,
      userName: user.email[0],
    };
    setTimeout(() => {
      aproveInspection(object).then((response) => {
        if (response.data.success) {
          fetchData();
          setToast({
            state: true,
            severity: "success",
            message:
              "The inspection was finished to the drop off with id: " +
              row.id +
              " successfully",
          });
        } else {
          setToast({
            state: true,
            severity: "error",
            message: response.data.message,
          });
          fetchData();
        }
      });
    }, 1000);
  };

  const handleOpenModal = (e) => {
    setShowModal(true);
    setRowData(e.row);
  };

  const handleGoodBadRequest = (row, value) => {
    setLoading(true);
    const object = {
      id: row.id,
      userName: user.email[0],
      approved: value,
    };
    requestBadGoods(object, user).then((response) => {
      if (response.data.success) {
        fetchData();
        setToast({
          state: true,
          severity: "success",
          message: "The status was changed successfully",
        });
      } else {
        setToast({
          state: true,
          severity: "error",
          message: response.data.message,
        });
        fetchData();
      }
    });
  };
  const getRowId = (row) =>
    typeOfInspection === "Empties" ? row.id : row.inspectionId;

  //-------------------------------------------------------------------------------------------
  // This works to apply stlyes to rows, depending of conditionals

  return {
    tableLocalData,
    columns,
    getRowId,
    handleExportExcel,
    chassisColumns,
    typeOfInspection,
    loading,
    filteredData,
    openDialog,
    chassisInspections,
    setOpenDialog,
  };
};
export default useTableController;
