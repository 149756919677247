function returnFormattedDate(date) {
  // mm-dd-yyyy
  return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
}

export const chassisInspectionRecord = (chassisList) => {
  return chassisList.map((chassis) => ({
    inspectionId: chassis.InspectId ? chassis.InspectId : "N/A",
    chassisNumber: chassis.ChassisNumber ? chassis.ChassisNumber : "N/A",
    ownerScacCode: chassis.ScacCodeOwner ? chassis.ScacCodeOwner : "N/A",
    inspectionState: chassis.stateInspection ? chassis.stateInspection : "N/A",
    carrierScacCode: chassis.CarrierScacCode ? chassis.CarrierScacCode : "N/A",
    reason: chassis.reason ? chassis.reason : "N/A",
    transactionDate: chassis.DateDelivery
      ? returnFormattedDate(new Date(chassis.DateDelivery))
      : "N/A",
  }));
};
