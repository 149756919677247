import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  IconButton,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useFieldRowController from "../../controllers/fieldRowController";
import "./fieldRow.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
const FieldRow = () => {
  const {
    locations,
    locationSelected,
    setLocationSelected,
    containerFiltered,
    setContainerFiltered,
    setTypeOfInspection,
    typeOfInspection,
  } = useFieldRowController();

  return (
    <>
      <Grid container spacing={2} paddingBottom={"25px"}>
        <Grid xxl={2} xl={2} lg={2} md={3} sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Locations</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Locations"
              value={locationSelected}
              onChange={(e) => setLocationSelected(e.target.value)}
            >
              {locations.map((location, index) => (
                <MenuItem key={index} value={location.value}>
                  {location.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xxl={2} xl={2} lg={2} md={3} sm={6} xs={12}>
          <TextField
            id="filled-multiline-static"
            label="Search By Container"
            fullWidth
            variant="outlined"
            value={containerFiltered}
            onChange={(e) => setContainerFiltered(e.target.value.toUpperCase())}
          />
        </Grid>{" "}
        <Grid xxl={3} xl={3} lg={3} md={0} sm={12} xs={12}></Grid>{" "}
        <Grid xxl={2} xl={2} lg={2} md={2} sm={12} xs={12}>
          <Button
            variant="contained"
            fullWidth
            className={`PendingInspect-Fieldrow-Button ${
              typeOfInspection === "Empties" ? "button-selected" : ""
            }`}
            onClick={() => setTypeOfInspection("Empties")}
          >
            Empties
          </Button>
        </Grid>{" "}
        <Grid xxl={2} xl={2} lg={2} md={2} sm={12} xs={12}>
          <Button
            variant="contained"
            fullWidth
            className={`PendingInspect-Fieldrow-Button ${
              typeOfInspection === "Chassis" ? "button-selected" : ""
            }`}
            onClick={() => setTypeOfInspection("Chassis")}
          >
            Chassis
          </Button>
        </Grid>{" "}
        <Grid xxl={1} xl={1} lg={1} md={2} sm={3} xs={3}>
          <IconButton
            aria-label="add"
            className="PendingInspect-Fieldrow-Add-ChassisToInspect-Button"
            onClick={() =>
              window.history.pushState(
                null,
                "Chassis Inspection",
                "/yms-admin/agreement-list"
              )
            }
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default FieldRow;
