import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import useModalController from '../../controllers/modalController';
const ModalComments = () => {
    const {
        showModal,
        setShowModal,
        rowData,
        setRowData,
        user,
        fetchData,
        setLoading,
        fullWidth,
        setFullWidth,
        handleSaveComment
    } = useModalController();



    return (
        <Dialog
            fullWidth={fullWidth}
            open={showModal || false}
            onClose={(e) => setShowModal(false)}
        >
            <DialogTitle>Edit comments</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add the comments that you need
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: '100%',
                    }}
                >
                    <TextField
                        id="filled-multiline-static"
                        label="Comments"
                        multiline
                        rows={8}
                        defaultValue={rowData ? rowData.Comment : ''}
                        variant="filled"
                        onChange={(e) => setRowData({ ...rowData, Comment: e.target.value })}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSaveComment()}>Save</Button>
            </DialogActions>
        </Dialog>

    )
}
export default ModalComments;