import { axiosInstance } from "./AppInsightsService";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};

const headersToDrayPower = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY_DRAYPOWER,
  "Content-Type": "application/json",
};
export const fetchPendingInspect = async () => {
  try {
    const config = {
      headers: headers,
      method: "get",
      url: process.env.REACT_APP_GET_PENDING_INSPECT,
      nameEvent: "PendingInspect-fetchPendingInspect",
    };
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const requestBadGoods = async (data) => {
  try {
    const config = {
      headers: headers,
      method: "post",
      url: process.env.REACT_APP_REQUEST_BAD_GOODS,
      timestamp: new Date().getTime(),
      data: data,
      nameEvent: "PendingInspect-requestBadGoods",
    };
    const response = await axiosInstance(config);
    console.log(response);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const aproveInspection = async (data) => {
  try {
    const config = {
      headers: headers,
      method: "post",
      url: process.env.REACT_APP_APROVE_INSPECTION,
      data: data,
      nameEvent: "PendingInspect-aproveInspection",
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const sendComments = async (data, user) => {
  try {
    const config = {
      headers: headers,
      method: "post",
      url: process.env.REACT_APP_SEND_COMMENTS,
      data: data,
      nameEvent: "PendingInspect-sendComments",
    };
    const response = await axiosInstance(config);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getLocationsListByOwner = async (ScacCode) => {
  try {
    const config = {
      headers: headersToDrayPower,
      method: "get",
      url: process.env.REACt_APP_GET_YARD_LOCATIONS_BY_OWNER,
      params: { ScacCode },
      nameEvent: "PendingInspect-getLocationsListByOwner",
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    console.log(error);
  }
};
