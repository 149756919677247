import * as React from "react";
export const StoreContext = React.createContext(null);
import Cookies from "universal-cookie";
import { fetchPendingInspect } from "../services/services";
import { getChassisInspections } from "../services/InspectionServices";
import { chassisInspectionRecord } from "../utils/chassisInspectionRecordModel";
import { useEventLibrary } from "../services/eventLibrary";
export const StoreProvider = ({ children }) => {
  //GLOBAL CONSTANTS  -----------------------------------------------------------------------------------------
  const [responseData, setResponseData] = React.useState([]);
  const [tableLocalData, setTableLocalData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const [user, setUser] = React.useState("");
  const [locationSelected, setLocationSelected] = React.useState("All");
  const [containerFiltered, setContainerFiltered] = React.useState("");
  const [typeOfInspection, setTypeOfInspection] = React.useState("Empties");
  const [toast, setToast] = React.useState({
    state: false,
    severity: "success",
    message: "Your transaction was successful",
  });
  const [chassisInspections, setChassisInspections] = React.useState([]);
  const cookies = new Cookies(null, { path: "/" });
  //USEFFECT-----------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    fetchData();
    if (cookies.get("sessionCosmos")) {
      setUser(cookies.get("sessionCosmos"));
      fetchChassisInspections(cookies.get("sessionCosmos").scac_code);
    } else {
      cookies.addChangeListener((session) => {
        if (session.name === "sessionCosmos") {
          setUser(session.value);
          fetchChassisInspections(session.value.scac_code);
        }
      });
    }
  }, []);

  //FUNCTIONS OR METHODS-----------------------------------------------------------------------------------------

  const fetchChassisInspections = async (userScacCode) => {
    getChassisInspections(userScacCode)
      .then((data) => {
        const dataFormatted = chassisInspectionRecord(data.Data);
        setChassisInspections(dataFormatted);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchData = () => {
    setLoading(true);

    setTableLocalData([]);
    fetchPendingInspect().then((data) => {
      setResponseData(data.data);
      const dataTableFormatted = data.data.map((row, index) => {
        return {
          ready: false,
          ...row,
        };
      });
      setTableLocalData(dataTableFormatted);
      setFilteredData(dataTableFormatted);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    setContainerFiltered("");
    if (locationSelected === "All") {
      setTableLocalData(responseData);
      setFilteredData(responseData);
    } else {
      const filteredData = responseData.filter(
        (row) => row.Location === locationSelected
      );
      setTableLocalData(filteredData);
      setFilteredData(filteredData);
    }
  }, [locationSelected]);

  React.useEffect(() => {
    ApplyFilterByContainer();
  }, [containerFiltered]);

  const ApplyFilterByContainer = () => {
    if (containerFiltered === "") {
      setFilteredData(tableLocalData);
    } else {
      const filteredData = tableLocalData.filter((row) =>
        row.containerNumber
          .toLowerCase()
          .includes(containerFiltered.toLowerCase())
      );
      setFilteredData(filteredData);
    }
  };

  React.useEffect(() => {
    ApplyFilterByContainer();
  }, [tableLocalData]);

  return (
    <StoreContext.Provider
      value={{
        responseData,
        typeOfInspection,
        filteredData,
        containerFiltered,
        locationSelected,
        toast,
        showModal,
        tableLocalData,
        loading,
        user,
        rowData,
        chassisInspections,
        setTypeOfInspection,
        setTableLocalData,
        fetchData,
        setLoading,
        setShowModal,
        setRowData,
        setUser,
        setToast,
        setLocationSelected,
        setContainerFiltered,
        setFilteredData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
