import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import useTableController from "../../controllers/tableController";
import useButtonsController from "../../controllers/buttonsController";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CssBaseline, Container } from "@mui/material";
import "./table.css";
import InspectionModal from "../Modals/InspectionModal/InspectionModal";

const Table = () => {
  const {
    columns,
    getRowId,
    loading,

    typeOfInspection,
    chassisColumns,
    openDialog,
    filteredData,
    chassisInspections,
    setOpenDialog,
  } = useTableController();
  const { handleReloadData } = useButtonsController();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <InspectionModal
        openDialogFactor={openDialog}
        setOpenDialog={setOpenDialog}
        handleReloadData={handleReloadData}
      />
      <CssBaseline />

      <Paper
        elevation={12}
        sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
      >
        <Typography id="titleTable-pending-inspect">
          {typeOfInspection} To Inspect
        </Typography>

        <DataGrid
          style={{ textAlign: "center", height: "70vh" }}
          rows={
            typeOfInspection === "Empties" ? filteredData : chassisInspections
          }
          columns={typeOfInspection === "Empties" ? columns : chassisColumns}
          getRowId={getRowId}
          autoHeight
          density="compact"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
                currentPage: 1,
              },
            },
          }}
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: GridToolbar,
          }}
          sx={{
            "& .super-app.good": {
              color: "#2acb66",
            },
            "& .super-app.bad": {
              color: "red",
            },
          }}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          disableRowSelectionOnClick
          loading={loading}
        />
      </Paper>
    </>
  );
};

export default Table;
