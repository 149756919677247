import React from "react";
import {
  Backdrop,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  MobileStepper,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import "./InspectionModal.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ChassisTruckImage from "../ChassisTruckImage/ChassisTruckImage";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import OkDefectRepairInputs from "../OkDefectRepairInputs2/Ok_Defect_Repair_Inputs";
import { saveChassisInspection } from "../../../services/InspectionServices";
import { useSnackBar } from "../../../store/SnackBarContext";
import { StoreContext } from "../../../store/storeContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InspectionModal = ({
  openDialogFactor,
  setOpenDialog,
  handleReloadData,
}) => {
  const { setToast } = React.useContext(StoreContext);
  const [payload, setPayload] = React.useState({
    Inspection: {
      IEP: null,
      VIN: null,
      StateRegistered: null,
      SizeType: null,
      LicensePlate: null,
      LicenseExpirationDate: null,
      Manufacturer: null,
      ManufactureYear: null,
      Refurbished: false,
      RefurbishYear: null,
      BrakeSystem: {
        ABS: false,
        ABSSystem: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        ServiceBrakes: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        CrackedOrMissingParts: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        NoAudibleAirLeaks: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        BrakeDrums: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        BrakeHosesAndLines: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        DrainAirTanks: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        BrakeChamber: {
          L_F: 0,
          R_F: 0,
          L_R: 0,
          R_R: 0,
        },
        BrakeMinimumThickness: {
          L_F: 0,
          R_F: 0,
          L_R: 0,
          R_R: 0,
        },
      },
      Suspension: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      CouplingDevices: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      LockingDevices: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      SliderAssembly: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      Frame: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      LandingGear: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      Electrical: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      WheelsAndRims: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      TypeOfTires: "Radial",
      Tires: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      Lubrication: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      Documentation: {
        OK: false,
        Defect: false,
        Repair: false,
        Description: null,
      },
      InspectorName: null,
      CompanyPerformingRepairs: null,
      Comments: null,
    },
  });
  const optionsOfTypeOfChassis = [
    "20-FLB",
    "20-GEN",
    "20-SLD",
    "20-STD",
    "40-EX5",
    "40-EX8",
    "40-GEN",
    "40-SLD",
    "40-STD",
    "45-GEN",
    "45-STD",
    "Other",
  ];

  const [somethingIsMissing, setSomethingIsMissing] = React.useState(false);
  const [payloadError, setPayloadError] = React.useState(false);
  const [optionsOfTypeOfChassisSelected, setOptionsOfTypeOfChassisSelected] =
    React.useState("");

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 5;
  const containerRef = React.useRef(null);
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep + 1;
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
      return newStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep - 1;
      if (containerRef.current) {
        containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
      return newStep;
    });
  };

  const hasError = () => {
    const { Inspection } = payload;

    const checkSubObject = (obj) => {
      return obj.OK || obj.Defect || obj.Repair;
    };

    const checkSubObjectDiffThanZero = (obj) => {
      return obj.L_F !== 0 && obj.L_R !== 0 && obj.R_R !== 0 && obj.R_F !== 0
        ? false
        : true;
    };

    // Verificar cada sub-objeto que tiene OK, Defect, Repair
    const subObjectsWithError = [
      ...(Inspection.BrakeSystem.ABS ? [Inspection.BrakeSystem.ABSSystem] : []),
      Inspection.BrakeSystem.ServiceBrakes,
      Inspection.BrakeSystem.CrackedOrMissingParts,
      Inspection.BrakeSystem.NoAudibleAirLeaks,
      Inspection.BrakeSystem.BrakeDrums,
      Inspection.BrakeSystem.BrakeHosesAndLines,
      Inspection.BrakeSystem.DrainAirTanks,
      Inspection.Suspension,
      Inspection.CouplingDevices,
      Inspection.LockingDevices,
      Inspection.SliderAssembly,
      Inspection.Frame,
      Inspection.LandingGear,
      Inspection.Electrical,
      Inspection.WheelsAndRims,
      Inspection.Tires,
      Inspection.Lubrication,
      Inspection.Documentation,
    ];

    // Verificar si alguno de los sub-objetos tiene todos los campos en false
    const hasError = subObjectsWithError.some(
      (subObject) => !checkSubObject(subObject)
    );

    const subObjectsWithErrorDiffThanZero = [
      Inspection.BrakeSystem.BrakeChamber,
      Inspection.BrakeSystem.BrakeMinimumThickness,
    ];

    const hasZeroError = subObjectsWithErrorDiffThanZero.some((subObject) =>
      checkSubObjectDiffThanZero(subObject)
    );

    // Verificar campos como IEP que no deben estar vacíos o nulos
    const fieldsToCheck = [
      Inspection.IEP,
      Inspection.VIN,
      Inspection.StateRegistered,
      Inspection.SizeType,
      Inspection.LicensePlate,
      Inspection.LicenseExpirationDate,
      Inspection.Manufacturer,
      Inspection.ManufactureYear,
      Inspection.Refurbished,
      ...(Inspection.Refurbished ? [Inspection.RefurbishYear] : []),
      Inspection.InspectorName,
      Inspection.CompanyPerformingRepairs,
    ];
    const isEmptyField = fieldsToCheck.some(
      (field) => field === null || field === ""
    );

    const totalHasError = hasError || isEmptyField || hasZeroError;
    return totalHasError;
  };

  function validatePayload() {
    setPayloadError(true);
  }

  function handleSaveInspection() {
    validatePayload();
    if (hasError()) {
      setToast({
        state: true,
        severity: "error",
        message: "Validation failed. Please check the highlighted fields.",
      });
    } else {
      let payloadToSend = payload;
      payloadToSend.InspectId = openDialogFactor.row.inspectionId;
      saveChassisInspection(payloadToSend)
        .then((response) => {
          if (response.IdInspection) {
            setToast({
              state: true,
              severity: "success",
              message: "Inspection saved successfully.",
            });
            setOpenDialog({ state: false, row: null });
            handleReloadData();
          } else
            setToast({
              state: true,
              severity: "error",
              message: "Error saving the inspection.",
            });
        })
        .catch((error) => {
          setToast({
            state: true,
            severity: "error",
            message: "Error saving the inspection.",
          });
        });
    }
  }

  function handleCleanPayload() {
    setPayload({
      Inspection: {
        IEP: null,
        VIN: null,
        StateRegistered: null,
        SizeType: null,
        LicensePlate: null,
        LicenseExpirationDate: null,
        Manufacturer: null,
        ManufactureYear: null,
        Refurbished: false,
        RefurbishYear: null,
        BrakeSystem: {
          ABS: false,
          ABSSystem: {
            OK: false,
            Defect: false,
            Repair: false,
            Description: null,
          },
          ServiceBrakes: {
            OK: false,
            Defect: false,
            Repair: false,
            Description: null,
          },
          CrackedOrMissingParts: {
            OK: false,
            Defect: false,
            Repair: false,
            Description: null,
          },
          NoAudibleAirLeaks: {
            OK: false,
            Defect: false,
            Repair: false,
            Description: null,
          },
          BrakeDrums: {
            OK: false,
            Defect: false,
            Repair: false,
            Description: null,
          },
          BrakeHosesAndLines: {
            OK: false,
            Defect: false,
            Repair: false,
            Description: null,
          },
          DrainAirTanks: {
            OK: false,
            Defect: false,
            Repair: false,
            Description: null,
          },
          BrakeChamber: {
            L_F: 0,
            R_F: 0,
            L_R: 0,
            R_R: 0,
          },
          BrakeMinimumThickness: {
            L_F: 0,
            R_F: 0,
            L_R: 0,
            R_R: 0,
          },
        },
        Suspension: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        CouplingDevices: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        LockingDevices: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        SliderAssembly: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        Frame: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        LandingGear: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        Electrical: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        WheelsAndRims: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        TypeOfTires: "Radial",
        Tires: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        Lubrication: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        Documentation: {
          OK: false,
          Defect: false,
          Repair: false,
          Description: null,
        },
        InspectorName: null,
        CompanyPerformingRepairs: null,
        Comments: null,
      },
    });
  }

  const handleClose = () => {
    handleCleanPayload();
    handleReloadData();
    setOpenDialog({ state: false, row: null });
  };

  return (
    <>
      {" "}
      <Dialog
        // fullScreen
        maxWidth="lg"
        scroll="paper"
        PaperProps={{
          className: "ChassisRentalLogger-InspectionModal-Paper-Dialog",
        }}
        className="ChassisRentalLogger-InspectionModal-Dialog"
        open={openDialogFactor.state}
        // onClick={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "sticky " }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>{" "}
        <Container
          ref={containerRef}
          maxWidth="xl"
          sx={{ height: "100%", overflowY: "scroll" }}
        >
          <Grid
            container
            className="ChassisRentalLoger-InspectionModal-Grid-Container"
            spacing={1}
          >
            <Grid xs={12}>
              <Typography className="ChassisRentalLoger-InspectionModal-title-text">
                Chassis Inspection
              </Typography>
            </Grid>
            {activeStep === 0 && (
              <>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="outlined-basic"
                    label="IEP"
                    onError={(error) => {
                      debugger;
                      setSomethingIsMissing(true);
                    }}
                    error={
                      (payloadError && payload.Inspection.IEP === "") ||
                      (payloadError && payload.Inspection.IEP === null)
                    }
                    value={payload.Inspection.IEP ? payload.Inspection.IEP : ""}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        Inspection: {
                          ...payload.Inspection,
                          IEP: e.target.value.toUpperCase(),
                        },
                      });
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="outlined-basic"
                    label="VIN"
                    // error={payloadError.Inspection.VIN}
                    variant="outlined"
                    onError={(error) => {
                      setSomethingIsMissing(true);
                    }}
                    error={
                      (payloadError && payload.Inspection.VIN === "") ||
                      (payloadError && payload.Inspection.VIN === null)
                    }
                    value={payload.Inspection.VIN ? payload.Inspection.VIN : ""}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        Inspection: {
                          ...payload.Inspection,
                          VIN: e.target.value.toUpperCase(),
                        },
                      });
                    }}
                    fullWidth
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    id="outlined-basic"
                    onError={(error) => {
                      setSomethingIsMissing(true);
                    }}
                    label="State Registered"
                    value={
                      payload.Inspection.StateRegistered
                        ? payload.Inspection.StateRegistered
                        : ""
                    }
                    error={
                      (payloadError &&
                        payload.Inspection.StateRegistered === "") ||
                      (payloadError &&
                        payload.Inspection.StateRegistered === null)
                    }
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        Inspection: {
                          ...payload.Inspection,
                          StateRegistered: e.target.value,
                        },
                      });
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Autocomplete
                    disablePortal
                    options={optionsOfTypeOfChassis}
                    sx={{ width: "100%" }}
                    value={optionsOfTypeOfChassisSelected}
                    onChange={(event, newValue) => {
                      if (newValue === "Other") {
                        setOptionsOfTypeOfChassisSelected(newValue);
                        return;
                      }
                      setOptionsOfTypeOfChassisSelected(newValue);
                      setPayload({
                        ...payload,
                        Inspection: {
                          ...payload.Inspection,
                          SizeType: newValue,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          (payloadError &&
                            payload.Inspection.SizeType === "") ||
                          (payloadError && payload.Inspection.SizeType === null)
                        }
                        onError={(error) => {
                          setSomethingIsMissing(true);
                        }}
                        label="Size/Type"
                      />
                    )}
                  />
                </Grid>{" "}
                <>
                  {optionsOfTypeOfChassisSelected === "Other" && (
                    <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                      <TextField
                        id="outlined-basic"
                        label="Size/Type"
                        error={
                          (payloadError &&
                            optionsOfTypeOfChassisSelected === "Other" &&
                            payload.Inspection.SizeType === "") ||
                          (payloadError &&
                            optionsOfTypeOfChassisSelected === "Other" &&
                            payload.Inspection.SizeType === null)
                        }
                        variant="outlined"
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            Inspection: {
                              ...payload.Inspection,
                              SizeType: e.target.value.toUpperCase(),
                            },
                          });
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}
                </>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  container
                  spacing={1}
                  className="ChassisRentalLoger-InspectionModal-grid-license-details"
                >
                  {" "}
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className="ChassisRentalLoger-InspectionModal-subtitle-text">
                      License Details
                    </Typography>
                  </Grid>{" "}
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      id="outlined-basic"
                      label="License Plate"
                      error={
                        (payloadError &&
                          payload.Inspection.LicensePlate === "") ||
                        (payloadError &&
                          payload.Inspection.LicensePlate === null)
                      }
                      value={
                        payload.Inspection.LicensePlate
                          ? payload.Inspection.LicensePlate
                          : ""
                      }
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          Inspection: {
                            ...payload.Inspection,
                            LicensePlate: e.target.value,
                          },
                        });
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>{" "}
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="Lincese Plate Expiration"
                        minDate={dayjs()}
                        value={dayjs(
                          payload.Inspection.LicenseExpirationDate
                            ? payload.Inspection.LicenseExpirationDate
                            : null
                        )}
                        sx={{ width: "100%" }}
                        onChange={() => {
                          setPayload({
                            ...payload,
                            Inspection: {
                              ...payload.Inspection,
                              LicenseExpirationDate:
                                dayjs().format("YYYY-MM-DD"),
                            },
                          });
                        }}
                        slotProps={{
                          textField: {
                            error:
                              (payloadError &&
                                payload.Inspection.LicenseExpirationDate ===
                                  "") ||
                              (payloadError &&
                                payload.Inspection.LicenseExpirationDate ===
                                  null),
                          },
                        }}
                        renderInput={(params) => <TextField s {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>{" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  container
                  spacing={1}
                  className="ChassisRentalLoger-InspectionModal-grid-manufacturer-details"
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className="ChassisRentalLoger-InspectionModal-subtitle-text">
                      Manufacturer Details
                    </Typography>
                  </Grid>{" "}
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    {" "}
                    <TextField
                      id="outlined-basic"
                      label="Manufacturer"
                      variant="outlined"
                      onError={(error) => {
                        setSomethingIsMissing(true);
                      }}
                      fullWidth
                      error={
                        (payloadError &&
                          payload.Inspection.Manufacturer === "") ||
                        (payloadError &&
                          payload.Inspection.Manufacturer === null)
                      }
                      value={
                        payload.Inspection.Manufacturer
                          ? payload.Inspection.Manufacturer
                          : ""
                      }
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          Inspection: {
                            ...payload.Inspection,
                            Manufacturer: e.target.value,
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="Manufacturer year"
                        maxDate={dayjs()}
                        value={
                          payload.Inspection.ManufactureYear
                            ? dayjs(payload.Inspection.ManufactureYear)
                            : null
                        }
                        views={["year"]}
                        sx={{ width: "100%" }}
                        onChange={() => {
                          setPayload({
                            ...payload,
                            Inspection: {
                              ...payload.Inspection,
                              ManufactureYear: dayjs().format("YYYY"),
                            },
                          });
                        }}
                        slotProps={{
                          textField: {
                            error:
                              (payloadError &&
                                payload.Inspection.ManufactureYear === "") ||
                              (payloadError &&
                                payload.Inspection.ManufactureYear === null),
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>{" "}
                </Grid>{" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  container
                  spacing={1}
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className="ChassisRentalLoger-InspectionModal-subtitle-text">
                      Refurbish Details
                    </Typography>
                  </Grid>{" "}
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl>
                      <FormLabel>Is the Chassis Refurbished?</FormLabel>
                      <RadioGroup
                        row
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            Inspection: {
                              ...payload.Inspection,
                              Refurbished: e.target.value === "Yes",
                              RefurbishYear: null,
                            },
                          });
                        }}
                        className="ChassisRentalLogger-InspectionModal-radiogroup-refurbished"
                      >
                        <FormControlLabel
                          value="Yes"
                          checked={payload.Inspection.Refurbished}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          checked={!payload.Inspection.Refurbished}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>{" "}
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="Refurbish year"
                        maxDate={dayjs()}
                        value={
                          payload.Inspection.RefurbishYear
                            ? dayjs(payload.Inspection.RefurbishYear)
                            : null
                        }
                        disabled={!payload.Inspection.Refurbished}
                        views={["year"]}
                        sx={{ width: "100%" }}
                        onChange={() => {
                          setPayload({
                            ...payload,
                            Inspection: {
                              ...payload.Inspection,
                              RefurbishYear: dayjs().format("YYYY"),
                            },
                          });
                        }}
                        slotProps={{
                          textField: {
                            error:
                              (payloadError &&
                                payload.Inspection.RefurbishYear === "" &&
                                payload.Inspection.Refurbished) ||
                              (payloadError &&
                                payload.Inspection.RefurbishYear === null &&
                                payload.Inspection.Refurbished),
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>{" "}
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} />
              </>
            )}{" "}
            {activeStep === 1 && (
              <>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  container
                  spacing={1}
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className="ChassisRentalLoger-InspectionModal-subtitle-text">
                      Brake System
                    </Typography>
                  </Grid>{" "}
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl>
                      <FormLabel>ABS</FormLabel>
                      <RadioGroup
                        row
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            Inspection: {
                              ...payload.Inspection,
                              BrakeSystem: {
                                ...payload.Inspection.BrakeSystem,
                                ABS: e.target.value === "Yes",
                              },
                            },
                          });
                        }}
                        className="ChassisRentalLogger-InspectionModal-radiogroup-refurbished"
                      >
                        <FormControlLabel
                          value="Yes"
                          checked={payload.Inspection.BrakeSystem.ABS}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          checked={!payload.Inspection.BrakeSystem.ABS}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>{" "}
                  {payload.Inspection.BrakeSystem.ABS && (
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <OkDefectRepairInputs
                        title={"ABS System"}
                        error={payloadError}
                        setPayloadError={setPayloadError}
                        setSomethingIsMissing={setSomethingIsMissing}
                        setPayload={setPayload}
                        objectToChangeInPayload={
                          payload.Inspection.BrakeSystem.ABSSystem
                        }
                        objectToChangeInPayloadText={
                          "Inspection.BrakeSystem.ABSSystem"
                        }
                        description={
                          "ABS System - No ABS light on dash, no ABS fault codes."
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Service Brakes"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.BrakeSystem.ServiceBrakes"
                    }
                    error={payloadError}
                    objectToChangeInPayload={
                      payload.Inspection.BrakeSystem.ServiceBrakes
                    }
                    objectToChangeInPayloadText={
                      "Inspection.BrakeSystem.ServiceBrakes"
                    }
                    description={
                      "Service Brakes - No absence of braking action."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Cracked Or Missing Parts"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.BrakeSystem.CrackedOrMissingParts"
                    }
                    error={payloadError}
                    objectToChangeInPayload={
                      payload.Inspection.BrakeSystem.CrackedOrMissingParts
                    }
                    objectToChangeInPayloadText={
                      "Inspection.BrakeSystem.CrackedOrMissingParts"
                    }
                    description={
                      "Inspect for cracked,broken, missing or loose brake parts."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"No Audible Air Leaks"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.BrakeSystem.NoAudibleAirLeaks"
                    }
                    error={payloadError}
                    objectToChangeInPayload={
                      payload.Inspection.BrakeSystem.NoAudibleAirLeaks
                    }
                    objectToChangeInPayloadText={
                      "Inspection.BrakeSystem.NoAudibleAirLeaks"
                    }
                    description={"No audible air leaks."}
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Brake Drums"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.BrakeSystem.BrakeDrums"
                    }
                    error={payloadError}
                    objectToChangeInPayload={
                      payload.Inspection.BrakeSystem.BrakeDrums
                    }
                    objectToChangeInPayloadText={
                      "Inspection.BrakeSystem.BrakeDrums"
                    }
                    description={
                      "Inspect brake drums for external cracking or missing pieces."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Brake Hoses And Lines"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.BrakeSystem.BrakeHosesAndLines"
                    }
                    error={payloadError}
                    objectToChangeInPayload={
                      payload.Inspection.BrakeSystem.BrakeHosesAndLines
                    }
                    objectToChangeInPayloadText={
                      "Inspection.BrakeSystem.BrakeHosesAndLines"
                    }
                    description={
                      "Inspect brake hoses, tubing, air lines, couplings, fittings, gladhands and gladhand seals - NO kinks, or blochages, NO worn, frayed loose hoses or lines. No hoses in contact with moving parts."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Drain Air Tanks"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.BrakeSystem.DrainAirTanks"
                    }
                    error={payloadError}
                    objectToChangeInPayload={
                      payload.Inspection.BrakeSystem.DrainAirTanks
                    }
                    objectToChangeInPayloadText={
                      "Inspection.BrakeSystem.DrainAirTanks"
                    }
                    description={
                      "Drain Air Tanks - No oil, water or other contaminants."
                    }
                  />
                </Grid>{" "}
              </>
            )}
            {activeStep === 2 && (
              <>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ChassisTruckImage
                    title="Brake Chamber"
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.BrakeSystem.BrakeChamber"
                    }
                    error={payloadError}
                    objectToChangeInPayload={
                      payload.Inspection.BrakeSystem.BrakeChamber
                    }
                    objectToChangeInPayloadText={
                      "Inspection.BrakeSystem.BrakeChamber"
                    }
                    description={
                      "Check and adjust travel on brake chamber Maximum travel = 2''."
                    }
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ChassisTruckImage
                    title="Brake Minimum Thickness"
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.BrakeSystem.BrakeMinimumThickness"
                    }
                    error={payloadError}
                    objectToChangeInPayload={
                      payload.Inspection.BrakeSystem.BrakeMinimumThickness
                    }
                    objectToChangeInPayloadText={
                      "Inspection.BrakeSystem.BrakeMinimumThickness"
                    }
                    description={
                      "Measure brake lining thickness Minimum Thickness = J4 Measured Thickness."
                    }
                  />
                </Grid>{" "}
              </>
            )}{" "}
            {activeStep === 3 && (
              <>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Suspension"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={"Inspection.Suspension"}
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.Suspension}
                    objectToChangeInPayloadText={"Inspection.Suspension"}
                    description={
                      "Inspect U-bolts, spring hangers, spring assemblies, leaves, torque radius or tracking components, axles or any other axle positioning parts. NO crackes, broken loose or missing parts."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Coupling Devices"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.CouplingDevices"
                    }
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.CouplingDevices}
                    objectToChangeInPayloadText={"Inspection.CouplingDevices"}
                    description={
                      "Inspect Kingpin upper coupler pate, slider, pintle hook, pintle hook latch, frame member providing support/attachment to pintle hook, fasteners. No broken or cracked components. No cracked welds to any components or parent metal. NO excessive wear or chipping of kingpin lip."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Locking Devices"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.LockingDevices"
                    }
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.LockingDevices}
                    objectToChangeInPayloadText={"Inspection.LockingDevices"}
                    description={
                      "Inspect all twist locks, push pins, handles, and safety devices. NO cracked welds. NO ineffective parts. NO excessively worn, bent, broken or missing parts."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Slider Assembly"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.SliderAssembly"
                    }
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.SliderAssembly}
                    objectToChangeInPayloadText={"Inspection.SliderAssembly"}
                    description={
                      "Inspect for missing, broken, damaged, binding, inoperative, worn, or cracked parts. NO damage or bends to slider stops. NO elongated slider-lock apertures in frame. NO cracked or improper welds to any components or parent metal."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Frame"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={"Inspection.Frame"}
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.Frame}
                    objectToChangeInPayloadText={"Inspection.Frame"}
                    description={
                      "Inspect main rails, bolsters, crossmembers, ICC Bumper, Light boxes, mudflap hangers. No cracked welds on any component or parent metal. NO broken, missing loose, sagging parts. No parts bent to affect mating of container to chassis."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Landing Gear"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={"Inspection.LandingGear"}
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.LandingGear}
                    objectToChangeInPayloadText={"Inspection.LandingGear"}
                    description={
                      "Check operation of landing gear in both directions. NO cracked welds on any component or parent metal. NO broken, missing or loose parts or fasteners."
                    }
                  />
                </Grid>{" "}
              </>
            )}
            {activeStep === 4 && (
              <Grid container spacing={2}>
                {" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Electrical"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={"Inspection.Electrical"}
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.Electrical}
                    objectToChangeInPayloadText={"Inspection.Electrical"}
                    description={
                      "Inspect seven way plug, wiring harness, lighting devices and reflectors. No broken, inoperative missing or loose parts."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Wheels And Rims"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.WheelsAndRims"
                    }
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.WheelsAndRims}
                    objectToChangeInPayloadText={"Inspection.WheelsAndRims"}
                    description={
                      "Inspect all wheels, rims spacers and fasteners. NO bent, broken, cracked, or improperly seated parts. NO elongated bolt holes or stripped parts"
                    }
                  />
                </Grid>{" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  container
                  spacing={1}
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className="ChassisRentalLoger-InspectionModal-subtitle-text">
                      Type of Tires
                    </Typography>
                  </Grid>{" "}
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl>
                      <RadioGroup
                        row
                        onChange={(e) => {
                          setPayload({
                            ...payload,
                            Inspection: {
                              ...payload.Inspection,
                              TypeOfTires: e.target.value,
                            },
                          });
                        }}
                        className="ChassisRentalLogger-InspectionModal-radiogroup-refurbished"
                      >
                        <FormControlLabel
                          value="Radial"
                          checked={payload.Inspection.TypeOfTires === "Radial"}
                          control={<Radio />}
                          label="Radial"
                        />
                        <FormControlLabel
                          value="Bias"
                          checked={payload.Inspection.TypeOfTires === "Bias"}
                          control={<Radio />}
                          label="Bias"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>{" "}
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Tires"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={"Inspection.Tires"}
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.Tires}
                    objectToChangeInPayloadText={"Inspection.Tires"}
                    description={
                      "Inspect all tires for, noticeable leaks, porper mating, separations, cuts through one or more ply of fabric. NO spot on tire with tread depth 2/32 or below when measured in major tread groove. Air all tubeless tires to 85PSI (+-5PSI), tube type tires to 90PSI (+/-5PSI), and radials 100PSI (+/-5)."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Lubrication"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={"Inspection.Lubrication"}
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.Lubrication}
                    objectToChangeInPayloadText={"Inspection.Lubrication"}
                    description={
                      "Lube all fitting on landing gears, gear boxes, slack adjusters, brake cams, twist locks, pushpins, slider mechanisms and sub-frames. Add oil to wheel hubs (if equipped with oil bath bearings)."
                    }
                  />
                </Grid>{" "}
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <OkDefectRepairInputs
                    title={"Documentation"}
                    setPayload={setPayload}
                    setPayloadError={setPayloadError}
                    setSomethingIsMissing={setSomethingIsMissing}
                    objectToChangeInPayloadErrorText={
                      "Inspection.Documentation"
                    }
                    error={payloadError}
                    objectToChangeInPayload={payload.Inspection.Documentation}
                    objectToChangeInPayloadText={"Inspection.Documentation"}
                    description={
                      "Check to ensure license plate is current and that license plate, registration and chassis are properly matched. Ensure that current registration and opy of most current FMCSA inspection is in document holder, Update decal on inspection plate and any inspection markings on unit. Ensure unit number is clearly marked and are correct on all four sides. Ensure that mudflaps are intact and secured to chassis."
                    }
                  />
                </Grid>{" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  container
                  spacing={1}
                >
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      id="outlined-basic"
                      label="Inspector Name"
                      variant="outlined"
                      onError={(error) => {
                        setSomethingIsMissing(true);
                      }}
                      error={
                        (payloadError &&
                          payload.Inspection.InspectorName === "") ||
                        (payloadError &&
                          payload.Inspection.InspectorName === null)
                      }
                      InputProps={{
                        className:
                          "ChassisRentalLogger-InspectionModal-InspectorName",
                      }}
                      fullWidth
                      value={
                        payload.Inspection.InspectorName
                          ? payload.Inspection.InspectorName
                          : ""
                      }
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          Inspection: {
                            ...payload.Inspection,
                            InspectorName: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>{" "}
                </Grid>{" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  container
                  spacing={1}
                >
                  {" "}
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      id="outlined-basic"
                      label="Company Performing Repairs"
                      variant="outlined"
                      onError={(error) => {
                        setSomethingIsMissing(true);
                      }}
                      error={
                        (payloadError &&
                          payload.Inspection.CompanyPerformingRepairs === "") ||
                        (payloadError &&
                          payload.Inspection.CompanyPerformingRepairs === null)
                      }
                      fullWidth
                      value={
                        payload.Inspection.CompanyPerformingRepairs
                          ? payload.Inspection.CompanyPerformingRepairs
                          : ""
                      }
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          Inspection: {
                            ...payload.Inspection,
                            CompanyPerformingRepairs: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>{" "}
                </Grid>{" "}
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  container
                  spacing={1}
                >
                  {" "}
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      id="standard-textarea"
                      label="Comments"
                      multiline
                      fullWidth
                      rows={2}
                      variant="filled"
                      value={
                        payload.Inspection.Comments
                          ? payload.Inspection.Comments
                          : ""
                      }
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          Inspection: {
                            ...payload.Inspection,
                            Comments: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>{" "}
                </Grid>
              </Grid>
            )}
          </Grid>{" "}
        </Container>{" "}
        <MobileStepper
          className="ChassisRentalLogger-InspectionModal-MobileStepper"
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            activeStep === maxSteps - 1 ? (
              <Button
                size="small"
                onClick={() => {
                  handleSaveInspection();
                }}
              >
                Save
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            ) : (
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            )
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Dialog>
    </>
  );
};

export default InspectionModal;
