import React from "react";
import ChassisImage from "../../../assets/chassisTruck.webp";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Typography,
  TextField,
  Tooltip,
  ButtonGroup,
  Button,
} from "@mui/material";
import "./ChassisTruckImage.css";

import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
const ChassisTruckImage = ({
  title,
  description,
  setPayload,
  error,
  objectToChangeInPayload,
  setSomethingIsMissing,
  objectToChangeInPayloadText,
}) => {
  const [itemError, setItemError] = React.useState(false);
  const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#222152",
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const updatePayload = (path, operation) => {
    setPayload((prevPayload) => {
      const keys = path.split(".");
      let temp = { ...prevPayload };

      keys.reduce((acc, key, index) => {
        if (index === keys.length - 1) {
          acc[key] =
            operation === "+"
              ? parseFloat((acc[key] + 0.1).toFixed(1))
              : parseFloat((acc[key] - 0.1).toFixed(1));
        } else {
          acc[key] = { ...acc[key] };
        }
        return acc[key];
      }, temp);

      return temp;
    });
  };

  React.useEffect(() => {
    if (error) {
      if (
        objectToChangeInPayload.L_F === "" ||
        objectToChangeInPayload.L_R === "" ||
        objectToChangeInPayload.R_F === "" ||
        objectToChangeInPayload.R_R === "" ||
        objectToChangeInPayload.L_F === null ||
        objectToChangeInPayload.L_R === null ||
        objectToChangeInPayload.R_F === null ||
        objectToChangeInPayload.R_R === null ||
        objectToChangeInPayload.L_F === 0 ||
        objectToChangeInPayload.L_R === 0 ||
        objectToChangeInPayload.R_F === 0 ||
        objectToChangeInPayload.R_R === 0
      ) {
        setItemError(true);
        setSomethingIsMissing(true);
      } else {
        setItemError(false);
        setSomethingIsMissing(false);
      }
    }
  }, [objectToChangeInPayload]);

  React.useEffect(() => {
    if (error) {
      if (
        objectToChangeInPayload.L_F === "" ||
        objectToChangeInPayload.L_R === "" ||
        objectToChangeInPayload.R_F === "" ||
        objectToChangeInPayload.R_R === "" ||
        objectToChangeInPayload.L_F === null ||
        objectToChangeInPayload.L_R === null ||
        objectToChangeInPayload.R_F === null ||
        objectToChangeInPayload.R_R === null ||
        objectToChangeInPayload.L_F === 0 ||
        objectToChangeInPayload.L_R === 0 ||
        objectToChangeInPayload.R_F === 0 ||
        objectToChangeInPayload.R_R === 0
      ) {
        setItemError(true);
        setSomethingIsMissing(true);
      } else {
        setItemError(false);
        setSomethingIsMissing(false);
      }
    }
  }, [error]);
  return (
    <>
      <Grid container spacing={1}>
        {" "}
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="ChassisRentalLoger-InspectionModal-subtitle-grid-item"
        >
          <Typography className="ChassisRentalLoger-InspectionModal-subtitle-text">
            {title}
          </Typography>{" "}
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <DarkToolTip
              onMouseLeave={handleTooltipClose}
              onMouseEnter={handleTooltipOpen}
              title={description ? description : ""}
              PopperProps={{
                disablePortal: true,
              }}
              open={open}
              placement="top-end"
              arrow
              disableFocusListener
            >
              <InfoIcon
                className={`ChassisRentalLoger-InspectionModal-subtitle-icon ${
                  error && itemError ? "error" : ""
                }`}
              />
            </DarkToolTip>
          </ClickAwayListener>
        </Grid>
        <Grid
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          className="ChassisRentalLogger-ChassisTruckImage-grid-textfields"
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="ChassisRentalLogger-ChassisTruckImage-grid-textfields"
          >
            <TextField
              id="outlined-basic"
              label="Left Front"
              variant="outlined"
              error={
                (itemError && objectToChangeInPayload.L_F === "") ||
                (itemError && objectToChangeInPayload.L_F === null) ||
                (itemError && objectToChangeInPayload.L_F === 0)
              }
              value={
                objectToChangeInPayload.L_F !== null &&
                objectToChangeInPayload.L_F !== undefined
                  ? objectToChangeInPayload.L_F
                  : 0
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={objectToChangeInPayload.L_F}
            />
            <ButtonGroup fullWidth size="small" aria-label="small button group">
              <Button
                variant="contained"
                onClick={() =>
                  updatePayload(objectToChangeInPayloadText + ".L_F", "+")
                }
              >
                {" "}
                +
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (objectToChangeInPayload.L_F > 0)
                    updatePayload(objectToChangeInPayloadText + ".L_F", "-");
                }}
              >
                -
              </Button>
            </ButtonGroup>{" "}
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="ChassisRentalLogger-ChassisTruckImage-grid-textfields"
          >
            <TextField
              id="outlined-basic"
              label="Left Rear"
              variant="outlined"
              error={
                (itemError && objectToChangeInPayload.L_R === "") ||
                (itemError && objectToChangeInPayload.L_R === null) ||
                (itemError && objectToChangeInPayload.L_R === 0)
              }
              value={
                objectToChangeInPayload.L_R !== null &&
                objectToChangeInPayload.L_R !== undefined
                  ? objectToChangeInPayload.L_R
                  : 0
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={objectToChangeInPayload.L_R}
            />
            <ButtonGroup fullWidth size="small" aria-label="small button group">
              <Button
                variant="contained"
                onClick={() =>
                  updatePayload(objectToChangeInPayloadText + ".L_R", "+")
                }
              >
                {" "}
                +
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (objectToChangeInPayload.L_R > 0)
                    updatePayload(objectToChangeInPayloadText + ".L_R", "-");
                }}
              >
                -
              </Button>
            </ButtonGroup>{" "}
          </Grid>
        </Grid>
        <Grid
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className="ChassisRentalLogger-ChassisTruckImage-grid-image"
        >
          <img
            src={ChassisImage}
            alt="Chassis Truck"
            className="ChassisRentalLogger-ChassisTruckImage"
          />
        </Grid>
        <Grid
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          className="ChassisRentalLogger-ChassisTruckImage-grid-textfields"
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="ChassisRentalLogger-ChassisTruckImage-grid-textfields"
          >
            <TextField
              id="outlined-basic"
              label="Right Front"
              variant="outlined"
              error={
                (itemError && objectToChangeInPayload.R_F === "") ||
                (itemError && objectToChangeInPayload.R_F === null) ||
                (itemError && objectToChangeInPayload.R_F === 0)
              }
              value={
                objectToChangeInPayload.R_F !== null &&
                objectToChangeInPayload.R_F !== undefined
                  ? objectToChangeInPayload.R_F
                  : 0
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={objectToChangeInPayload.R_F}
            />
            <ButtonGroup fullWidth size="small" aria-label="small button group">
              <Button
                variant="contained"
                onClick={() =>
                  updatePayload(objectToChangeInPayloadText + ".R_F", "+")
                }
              >
                {" "}
                +
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (objectToChangeInPayload.R_F > 0)
                    updatePayload(objectToChangeInPayloadText + ".R_F", "-");
                }}
              >
                -
              </Button>
            </ButtonGroup>{" "}
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="ChassisRentalLogger-ChassisTruckImage-grid-textfields"
          >
            <TextField
              id="outlined-basic"
              label="Right Rear"
              variant="outlined"
              error={
                (itemError && objectToChangeInPayload.R_R === "") ||
                (itemError && objectToChangeInPayload.R_R === null) ||
                (itemError && objectToChangeInPayload.R_R === 0)
              }
              value={
                objectToChangeInPayload.R_R !== null &&
                objectToChangeInPayload.R_R !== undefined
                  ? objectToChangeInPayload.R_R
                  : 0
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={objectToChangeInPayload.R_R}
            />
            <ButtonGroup fullWidth size="small" aria-label="small button group">
              <Button
                variant="contained"
                onClick={() =>
                  updatePayload(objectToChangeInPayloadText + ".R_R", "+")
                }
              >
                {" "}
                +
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (objectToChangeInPayload.R_R > 0)
                    updatePayload(objectToChangeInPayloadText + ".R_R", "-");
                }}
              >
                -
              </Button>
            </ButtonGroup>{" "}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChassisTruckImage;
