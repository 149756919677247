import { axiosInstance } from "./AppInsightsService";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY_DRAYPOWER,
  "Content-Type": "application/json",
};
export const saveChassisInspection = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_SAVE_INSPECTION_CHASSIS,
      method: "POST",
      data: (payload = { payload }),
      nameEvent: "PendingInspect-saveChassisInspection",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const getChassisInspections = async (scacCodeOwner) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_INSPECTION_CHASSIS,
      method: "GET",
      params: { scacCodeOwner },
      nameEvent: "PendingInspect-getChassisInspections",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
