import {
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Tooltip,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./OkDefectRepairInputs.css";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

const OkDefectRepairInputs = ({
  title,
  setPayload,
  description,
  objectToChangeInPayload,
  objectToChangeInPayloadText,
  error,
  setSomethingIsMissing,
}) => {
  const [itemError, setItemError] = React.useState(false);
  const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#222152",
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const updatePayload = (path, value) => {
    setPayload((prevPayload) => {
      const keys = path.split(".");
      let temp = { ...prevPayload };

      keys.reduce((acc, key, index) => {
        if (index === keys.length - 1) {
          acc[key] = value;
        } else {
          acc[key] = { ...acc[key] };
        }
        return acc[key];
      }, temp);

      return temp;
    });
  };

  const handleCheckboxChange = (key) => {
    updatePayload(objectToChangeInPayloadText + ".OK", key === "OK");
    updatePayload(objectToChangeInPayloadText + ".Defect", key === "Defect");
    updatePayload(objectToChangeInPayloadText + ".Repair", key === "Repair");
    setItemError(false);
  };

  React.useEffect(() => {
    if (error) {
      if (
        objectToChangeInPayload.OK === false &&
        objectToChangeInPayload.Defect === false &&
        objectToChangeInPayload.Repair === false
      ) {
        setSomethingIsMissing(true);
        setItemError(true);
      } else {
        setSomethingIsMissing(false);
        setItemError(false);
      }
    } else {
      setSomethingIsMissing(false);
      setItemError(false);
    }
  }, [error]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="ChassisRentalLoger-InspectionModal-subtitle-grid-item"
        >
          <Typography className="ChassisRentalLoger-InspectionModal-subtitle-text">
            {title}
          </Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <DarkToolTip
              onMouseLeave={handleTooltipClose}
              onMouseEnter={handleTooltipOpen}
              title={description ? description : ""}
              PopperProps={{
                disablePortal: true,
              }}
              open={open}
              placement="top-end"
              arrow
              disableFocusListener
            >
              <InfoIcon
                className={`ChassisRentalLoger-InspectionModal-subtitle-icon ${
                  error && itemError ? "error" : ""
                }`}
              />
            </DarkToolTip>
          </ClickAwayListener>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="ChassisRentalLogger-InputOkDefectRepair-grid-items"
        >
          <FormControl>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={objectToChangeInPayload.OK}
                    onChange={() => handleCheckboxChange("OK")}
                  />
                }
                label="OK"
                labelPlacement="top"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={objectToChangeInPayload.Defect}
                    onChange={() => handleCheckboxChange("Defect")}
                  />
                }
                label="Defect"
                labelPlacement="top"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={objectToChangeInPayload.Repair}
                    onChange={() => handleCheckboxChange("Repair")}
                  />
                }
                label="Repair"
                labelPlacement="top"
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="ChassisRentalLogger-InputOkDefectRepair-grid-items"
        >
          <TextField
            id="standard-textarea"
            label="Description of Defect/Repair"
            onChange={(e) => {
              updatePayload(
                objectToChangeInPayloadText + ".Description",
                e.target.value
              );
            }}
            multiline
            fullWidth
            rows={2}
            variant="filled"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OkDefectRepairInputs;
