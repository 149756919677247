import * as React from "react";
import { StoreContext } from "../store/storeContext";
import { getLocationsListByOwner } from "../services/services";
import Cookies from "universal-cookie";
const useFieldRowController = () => {
  const cookies = new Cookies();
  const {
    locationSelected,
    setLocationSelected,
    containerFiltered,
    setContainerFiltered,
    setTypeOfInspection,
    typeOfInspection,
  } = React.useContext(StoreContext);
  const [locations, setLocations] = React.useState([]);

  React.useEffect(() => {
    try {
      getLocationsListByOwner(cookies.get("sessionCosmos").scac_code).then(
        (response) => {
          let locations = [];
          locations = [{ value: "All", text: "All" }];
          response.data.Data.map((location) => {
            locations.push({
              value: location.YardId.CodeName,
              text: location.YardId.CodeName,
            });
          });

          setLocations(locations);
        }
      );
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  }, []);
  return {
    locations,
    locationSelected,
    setLocationSelected,
    containerFiltered,
    setContainerFiltered,
    setTypeOfInspection,
    typeOfInspection,
  };
};
export default useFieldRowController;
